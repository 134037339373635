import React from 'react'
import styled from '@emotion/styled'

// Components
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons'
import Header1 from '../atoms/header-1'
import Paragraph1 from '../atoms/paragraph-1'
import Button1 from '../atoms/button-1'

// Styles
const Container = styled.section`
  position: relative;
  flex-wrap: wrap;
  padding: 150px 0 50px 0;
  width: 100%;
  height: 80vh;
  min-height: 700px;
  @media (max-width: 768px) {
    height: 1000px;
  }
`
const WrapperLeft = styled.section`
  padding: 0 30px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 0 15px;
    flex-wrap: wrap;
  }
`
const WrapperRight = styled.section`
  padding: 0 30px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 0 15px;
    > section:nth-of-type(1) {
      order: 2;
    }
    > section:nth-of-type(2) {
      order: 1;
    }
    > section:nth-of-type(3) {
      order: 3;
    }
  }
`
const TextContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
  @media (max-width: 768px) {
    width: 100%;
  }
`
const TextWrapper = styled.section`
  padding: 0 30px;
  max-width: 450px;
  @media (max-width: 768px) {
    padding: 0 15px;
  }
`
const ImageContainer = styled.section`
  padding: 0 30px;
  width: 45%;
  max-width: 500px;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 450px;
  }
`
const ControlContainer = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 75px 0;
`

class HorizontalScroll extends React.Component {
  constructor(props) {
    super(props)
    // Eliminate warnings
    props = {
      x: 0
    }
  }

  render() {
    return(
      <Container>
        <Parallax pages={2} scrolling={false} horizontal ref={ref => this.parallax = ref}>
          <ParallaxLayer offset={0} speed={0.5}>
            
            <WrapperLeft>
              <TextContainer>
                <TextWrapper>
                  <Header1>
                    {this.props.data[0].title}
                  </Header1>
                  <Paragraph1>
                    {this.props.data[0].paragraph1}
                  </Paragraph1>
                  <Paragraph1>
                    {this.props.data[0].paragraph2}          
                  </Paragraph1>
                  <Paragraph1>
                    {this.props.data[0].paragraph3}
                  </Paragraph1>
                </TextWrapper>
              </TextContainer>

              <ImageContainer>
                {this.props.data[0].image}
              </ImageContainer>

              <ControlContainer>
                  <Button1 onClick={() => this.parallax.scrollTo(1)} text={this.props.data[1].title} left={false}/>
              </ControlContainer>
            </WrapperLeft>

          </ParallaxLayer>
          <ParallaxLayer offset={1} speed={0.5}>
            
            <WrapperRight>
              <ImageContainer>
                {this.props.data[1].image}
              </ImageContainer>

              <TextContainer>
                <TextWrapper>
                  <Header1>
                    {this.props.data[1].title}
                  </Header1>
                  <Paragraph1>
                    {this.props.data[1].paragraph1}
                  </Paragraph1>
                  <Paragraph1>
                    {this.props.data[1].paragraph2}          
                  </Paragraph1>
                  <Paragraph1>
                    {this.props.data[1].paragraph3}
                  </Paragraph1>
                </TextWrapper>
              </TextContainer>

              <ControlContainer>
                  <Button1 onClick={() => this.parallax.scrollTo(0)} text={this.props.data[0].title} left/>
              </ControlContainer>

            </WrapperRight>

          </ParallaxLayer>
        </Parallax>
      </Container>
    )
  }
}

export default HorizontalScroll