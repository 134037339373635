import React from 'react'
/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import MediaQuery from 'react-responsive'

// Animations
const scroll = keyframes`
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  90% { transform: translateY(15px); opacity: 1;}
  100% { transform: translateY(15px); opacity: 0; }
`

// Styles
const Container = styled.div`
  z-index: 100;  
  position: absolute;
  top: 87vh;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  pointer-events: none;
  opacity: 0;
  transform: translateY(-15px);
  transition: 1s;
`
const Layer1 = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  margin: auto;

  width :37px;
  height: 59px;
`
const Layer2 = styled.div`
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 3px solid #70926E;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
`
const Layer3 = styled.div`
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color:  #70926E;
`

class AnimatedMouseScroll extends React.Component {
  componentDidMount() {
    if(typeof window !== 'undefined' && document.querySelector('#animated-mouse')) {
      const animatedMouse = document.querySelector('#animated-mouse')
      window.setTimeout(() => {
        if(window.scrollY === 0) {
          animatedMouse.style.transform = 'translateY(0)'
          animatedMouse.style.opacity = '1'
        }
      }, 5000)
      window.addEventListener('scroll', function hideMouse() {
        if(document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
          animatedMouse.style.transform = 'translateY(-15px)'
          animatedMouse.style.opacity = '0'
          this.removeEventListener('scroll', hideMouse, true)
        }
      })
    }
  }

  render() {
    return(
      <MediaQuery  minWidth={1200}>
        <Container id='animated-mouse'>
          <Layer1>
            <Layer2>
              <Layer3 css={css`
                animation: ${scroll} 2s cubic-bezier(0, 0, 0.2, 1) infinite;
                `}/>
            </Layer2>
          </Layer1>
        </Container>      
      </MediaQuery>
    )
  }
}

export default AnimatedMouseScroll