/** @jsx jsx */
import { css, jsx } from '@emotion/core'

const Button1 = ({text, left, onClick}) => (
  <button
    onClick={onClick}
    css={css`
      outline: none;
      background: none;
      border: none;

      display: block;
      color: #70926E;
      font-family: 'Poiret One';
      font-size: 1.75em;
      transition: 0.1s;
      opacity: 0.8;
      cursor: pointer;
      svg {
        fill: #70926E;
        opacity: 0.8;
        transition: 0.1s;
      }
      &:hover {
        color: #CCCCCC;
        svg {
          fill: #CCCCCC;
        }
      }
    `}
  >
    {left &&
      <svg width="37" height="24" css={css`margin-right: 10px;`} viewBox="0 0 37 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.93934 10.9393C0.353553 11.5251 0.353553 12.4749 0.93934 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97918 12.6066 1.3934C12.0208 0.80761 11.0711 0.80761 10.4853 1.3934L0.93934 10.9393ZM37 10.5L2 10.5L2 13.5L37 13.5L37 10.5Z" />
      </svg>
    }
    {text}
    {!left &&
      <svg width="37" height="24" css={css`margin-left: 10px;`} viewBox="0 0 37 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M36.0607 13.0607C36.6464 12.4749 36.6464 11.5251 36.0607 10.9393L26.5147 1.3934C25.9289 0.807612 24.9792 0.807612 24.3934 1.3934C23.8076 1.97918 23.8076 2.92893 24.3934 3.51472L32.8787 12L24.3934 20.4853C23.8076 21.0711 23.8076 22.0208 24.3934 22.6066C24.9792 23.1924 25.9289 23.1924 26.5147 22.6066L36.0607 13.0607ZM0 13.5H35V10.5H0L0 13.5Z" />
      </svg>
    }
  </button>
)

export default Button1