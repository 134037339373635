import React from 'react'
import styled from '@emotion/styled'

// Components
import InfoBoxGrey from '../molecules/info-box-grey'

// Styles
const Container = styled.section`
  z-index: 1;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  height: 850px;
  @media (min-width: 1200px) {
    height: 1000px;
  }
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`
const ContainerInfo = styled.section`
  position: relative;
  width: 50%;
  height: 100%;
  @media (max-width: 1024px) {
    width: 50.1%;
    height: auto;
    order: 1;
  }
  @media (max-width: 850px) {
    width: 70%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`
const ContainerBackground = styled.section`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
`

class CategoryInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: 0,
    }

    this.changeSelected = this.changeSelected.bind(this)
  }

  changeSelected(event) {
    this.setState({selected: event.currentTarget.dataset['buttonid']})
  }

  render() {
    return(
      <Container>

        <ContainerBackground>
          {this.props.data.imgBackground}
        </ContainerBackground>

        <ContainerInfo>
          <InfoBoxGrey title={this.props.data.title} text={this.props.data.text} link={this.props.data.link} linkText={this.props.data.linkText}/>
        </ContainerInfo>

      </Container>
    )
  }
}

export default CategoryInfo