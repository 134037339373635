import React from 'react'

// Components
import Layout from '../components/layout'
import SEO from '../components/organisms/seo'
import HorizontalScroll from '../components/organisms/horizontal-scroll'
import CategoryInfo from '../components/organisms/category-info'
import Masthead from '../components/organisms/masthead'
import AnimatedMouseScroll from '../components/atoms/animated-mouse-scroll'

// Images
import ImgMastheadHome from '../components/images/masthead/img-masthead-home'
import ImgTagHome from '../components/images/tag/img-tag-home'
import ImgHomeCatharine from '../components/images/_home/img-home-catharine'
import ImgHomeOurServices from '../components/images/_home/img-home-our-services'
import ImgHomeSketch from '../components/images/_home/img-home-sketch';

// Data
const dataMasthead = {
  mastheadImage: <ImgMastheadHome/>,
  tagImage: <ImgTagHome/>,
  tagTitle: 'Welcome',
}
const dataHorizontalScroll = [
  {
    title: 'Welcome',
    paragraph1: 'Catharine’s studio is based on serving people. She consults with clients, listens to their story and focuses on design, through the study of the environment and cultural relevance to its use. She continues to learn from and collaborate with design professionals - architects, engineers, ecologists and artists - to integrate expertise and provide a holistic approach to the constructability of a landscape.',
    paragraph2: 'Catharine has contributed to the community in which she lives, serving on the planning commission for many years. She is a registered landscape architect in Pennsylvania and has many interests including:  gardening, artistic furnishings, home renovation and real estate. Her passion is to blend her love for the natural environment with art and architecture, while restoring ecological processes of the site.',
    image: <ImgHomeCatharine/>,
  },
  {
    title: 'Design',
    paragraph1: 'Being rooted in the ecology of our native habitat, our design intent is to define the graceful character of the built landscape and facilitate interaction with our surroundings. Bringing new life to a landscape gives individuals the understanding of these processes to experience and enjoy the garden as it changes and flourishes, and to appreciate everything beautiful in its time.',
    image: <ImgHomeSketch/>,
  }
]
const dataCategoryInfo = {
  title: 'Our Services',
  text: 'Our services are focused on but not limited to ecological design for institutional, commercial, residential and community projects. We are excited to talk to you about your project!',
  link: '/landscape-architecture',
  linkText: 'Learn More',
  imgBackground: <ImgHomeOurServices/>,
}

const IndexPage = () => (
  <Layout>
    <Masthead 
      mastheadImage={dataMasthead.mastheadImage} 
      tagImage={dataMasthead.tagImage}
      tagTitle={dataMasthead.tagTitle}
      tagType={dataMasthead.tagType}
    />
    <SEO title="Welcome" keywords={[`landscaping`, `landscape architecture`, `catharine ann farnen llc`, `pennsylvania landscaping`, `home`]} />
    <AnimatedMouseScroll/>
    <HorizontalScroll data={dataHorizontalScroll}/>
    <CategoryInfo data={dataCategoryInfo}/>
  </Layout>
)

export default IndexPage