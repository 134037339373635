import React from 'react'
import styled from '@emotion/styled'

// Components
import Header1 from '../atoms/header-1'
import Paragraph1 from '../atoms/paragraph-1'
import Link2 from '../atoms/link-2'

// Styles
const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(241, 240, 238, 0.90);
`
const ContainerHeader = styled.section`

`
const ContainerText = styled.section`
  max-width: 400px;
  padding: 0 15px;
  margin-bottom: 30px;
`
const ContainerLink = styled.section`
  position: relative;
  width: 150px;
`

const InfoBoxGrey = ({ title, text, link, linkText }) => (
  <Container>
    <ContainerHeader>
      <Header1>{title}</Header1>
    </ContainerHeader>
    <ContainerText>
      <Paragraph1>{text}</Paragraph1>
    </ContainerText>
    <ContainerLink>
      <Link2 to={link} text={linkText}/>
    </ContainerLink>
  </Container>
)

export default InfoBoxGrey